import React from 'react';

export const UniitLogo = () => {
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <img
        src="https://uniit.sk/assets/logo-white.svg"
        alt="UNIIT logo"
        style={{ margin: 'auto' }}
      />
    </div>
  );
};
