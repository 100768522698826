import React from 'react';
import { TemplateBackstageLogo } from '@backstage/plugin-home';
import { Grid, Divider, makeStyles } from '@material-ui/core';
import { UniitLogo } from './UniitLogo';

export const HomePageLogo = () => {
  const { svg, path } = makeStyles(() => ({
    svg: {
      width: 'auto',
      height: 80,
      margin: 8,
    },
    path: {
      fill: '#7df3e1',
    },
  }))();
  return (
    <Grid
      item
      container
      justifyContent="center"
      style={{ margin: 0, width: '100%', marginTop: 32 }}
    >
      <Grid item>
        <UniitLogo />
      </Grid>
      <Grid item style={{ display: 'flex' }}>
        <Divider
          style={{
            background: 'gray',
            width: '1px',
            height: '75%',
            margin: 'auto',
          }}
        />
      </Grid>
      <Grid item>
        <TemplateBackstageLogo classes={{ svg, path }} />
      </Grid>
    </Grid>
  );
};
