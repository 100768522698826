import React from 'react';
import { Grid } from '@material-ui/core';
import { HomePageSearchBar as HPSBOriginal } from '@backstage/plugin-search';
import { makeStyles } from '@material-ui/core';

export const HomePageSearchBar = () => {
  const classes = makeStyles(theme => ({
    searchBar: {
      display: 'flex',
      maxWidth: '60vw',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      padding: '8px 0',
      borderRadius: '50px',
      margin: '8px auto',
    },
    searchBarOutline: {
      borderStyle: 'none',
    },
  }))();

  return (
    <Grid container item xs={12} alignItems="center" direction="row">
      <HPSBOriginal
        classes={{ root: classes.searchBar }}
        InputProps={{ classes: { notchedOutline: classes.searchBarOutline } }}
        placeholder="Search"
      />
    </Grid>
  );
};
