import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  HomePageTopVisited,
  HomePageRecentlyVisited,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { HomePageLogo } from './HomePageLogo';
import { HomePageSearchBar } from './HomePageSearchBar';

export const HomePage = () => {
  return (
    <Grid container spacing={4} style={{ margin: 0, width: '100%' }}>
      <HomePageLogo />
      <HomePageSearchBar />
      <Grid item xs={12} lg={6}>
        <HomePageTopVisited />
      </Grid>
      <Grid item xs={12} lg={6}>
        <HomePageRecentlyVisited />
      </Grid>
      <Grid item xs={12} lg={8}>
        <HomePageStarredEntities />
      </Grid>
    </Grid>
  );
};
